var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"admin-dashboard"}},[_c('div',{staticClass:"dashboard-header"},[_vm._m(0),_c('div',{staticClass:"header-boxes"},_vm._l((_vm.boxes),function(box,key){return _c('HeaderBox',{key:key,attrs:{"icon":box.icon,"numbers":_vm.getBoxNumbers(box.numbers),"title":box.title}})}),1)]),_c('div',{staticClass:"dashboard-body"},[_c('section',{staticClass:"dashboard-registrations"},[_c('h3',{staticClass:"dashboard-title"},[_vm._v("Pending registrations requests")]),(_vm.pendings)?_c('v-data-table',{staticClass:"row-clickable",attrs:{"headers":_vm.pendingRegistrationsRequests.headers,"items":_vm.pendings,"item-key":"name","hide-default-footer":true,"disable-sort":""},on:{"click:row":function (item) { return _vm.rowClick(item); }},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t("labels." + value))+" ")]}}],null,false,872831540)}):_vm._e()],1),_c('section',{staticClass:"dashboard-profit-products"},[_c('div',{staticClass:"dashboard-profit"},[_vm._m(1),_c('div',{staticClass:"donut-chart-wrapper"},[_c('div',{staticClass:"donut-chart-info"},[_c('ul',_vm._l((_vm.profitPercentages),function(item,index){return _c('li',{key:("percentage-" + index)},[_c('div',[_vm._v(_vm._s(item.name))]),_c('div',[_vm._v(_vm._s(item.value)+"%")])])}),0)]),_c('div',{staticClass:"donut-chart"},[_c('div',{attrs:{"id":"chart"}},[_c('apexchart',{attrs:{"type":"donut","options":_vm.chartOptions,"height":"200px","series":_vm.series}})],1)])])]),_c('div',{staticClass:"dashboard-products"},[_c('h3',{staticClass:"dashboard-title"},[_vm._v("Latest Products")]),_c('h5',[_vm._v("Lorem ipsum dolor sit amet.")]),(_vm.products)?_c('v-data-table',{attrs:{"headers":_vm.latestProducts.headers,"items":_vm.products.data,"item-key":"name","hide-default-footer":true,"disable-sort":""},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t("labels." + value))+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$dayjs(item.created_at).format("DD.MM.YYYY"))+" ")]}}],null,false,1851202910)}):_vm._e()],1)]),_c('section',{staticClass:"dashboard-orders"},[_c('h3',{staticClass:"dashboard-title"},[_vm._v("Latest orders")]),(_vm.orders)?_c('v-data-table',{attrs:{"headers":_vm.latestOrders.headers,"items":_vm.orders.data,"item-key":"name","hide-default-footer":true,"disable-sort":""},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$dayjs(value).format("DD.MM.YYYY"))+" ")]}},{key:"item.price.value",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$formatMoney(value, item.price.currency))+" ")]}},{key:"item.state",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.$t("orders.state." + value))+" ")]}}],null,false,2604575252)}):_vm._e()],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header-title"},[_c('h1',[_vm._v("Dashboard")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-profit-titles"},[_c('h3',{staticClass:"dashboard-title"},[_vm._v("Profit percentages")]),_c('h5',[_vm._v("Lorem ipsum dolor sit amet.")])])}]

export { render, staticRenderFns }